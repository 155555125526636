import React from 'react';
import styled from 'styled-components';
import scbg from '../assets/img/semiconductor.jpg';
import robotImg from '../assets/img/logitic.jpg';
import agv from '../assets/img/agv.png';
import acs from '../assets/img/acs.png';
import cleaner from '../assets/img/cleaner.jpg';
import Laser from '../assets/img/LaserProcessing.jpg';
import shipping from '../assets/img/shippingCleanner.png';
import smartFarm from '../assets/img/smartFarm.png';
const MainContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
`;

const HeroSection = styled.section`
    height: 100vh;
    min-height: 100vh;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: url(${scbg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0 20px;
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }
    
    > div {
        position: relative;
        z-index: 2;
    }
`;

const Title = styled.h1`
    font-size: 3rem;
    margin-bottom: 1rem;
    
    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const SubTitle = styled.p`
    font-size: 1.5rem;
    color: #ddd;
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const Section = styled.section`
    padding: 80px 20px;
`;

const SectionTitle = styled.h2`
    color: black;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
`;

const ProjectGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 20px;
`;

const ProjectCard = styled.div`
    background: #2a2a2a;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease;
    
    &:hover {
        transform: translateY(-5px);
    }
`;

const ProjectImage = styled.div`
    width: 100%;
    height: 200px;
    background-color: #3498DB;
    background-size: cover;
    background-position: center;
`;

const ProjectInfo = styled.div`
    padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;

const ProjectDescription = styled.p`
    color: #aaa;
    font-size: 0.9rem;
    line-height: 1.5;
`;

const SkillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
`;

const Skill = styled.span`
    background: #3498DB;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.8rem;
`;

const AboutSection = styled(Section)`
    background-color: #222;
`;

const AboutContainer = styled.div`
    display: flex;
    gap: 4rem;
    max-width: 1000px;
    margin: 0 auto;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }
`;

const ProfileImage = styled.div`
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #3498DB;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    border: 4px solid #3498DB;
    
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
`;

const AboutContent = styled.div`
    flex: 1;
`;

const AboutText = styled.p`
    font-size: 1.1rem;
    line-height: 1.8;
    color: #ddd;
    margin-bottom: 1.5rem;
`;

const Footer = styled.footer`
    background-color: #151515;
    padding: 60px 20px 30px;
`;

const FooterContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        text-align: center;
    }
`;

const FooterSection = styled.div`
    h3 {
        color: #3498DB;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }
`;

const ContactInfo = styled.div`
    margin-bottom: 1rem;
    
    p {
        color: #ddd;
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        
        @media (max-width: 768px) {
            justify-content: center;
        }
    }
`;

const LocationInfo = styled.div`
    p {
        color: #ddd;
        margin-bottom: 0.5rem;
        line-height: 1.6;
    }
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const SocialIcon = styled.a`
    color: #ddd;
    font-size: 1.5rem;
    transition: color 0.3s ease;
    
    &:hover {
        color: #3498DB;
    }
`;

const Copyright = styled.div`
    text-align: center;
    color: #666;
    margin-top: 3rem;
    padding-top: 1.5rem;
    border-top: 1px solid #333;
`;

const DevPortfolio = () => {
    return (
        <MainContainer>
            <HeroSection>
                <div>
                    <Title>EggPlant는 <br/>
                    미래의 공장을 만들고 있습니다</Title>
                    <SubTitle>스마트팩토리의 설비와 물류 자동화를 위한
                    통합 제어 및 실시간 모니터링 플랫폼을 제공합니다</SubTitle>
                </div>
            </HeroSection>

            <AboutSection>
                <SectionTitle style={{color: 'white'}}>Introduction</SectionTitle>
                <AboutContainer>
                    <ProfileImage style={{ backgroundImage: `url(${robotImg})` }} />
                    <AboutContent>
                        <AboutText>
                        EGGPLANT는
                        모든 유형과 브랜드의 로봇과 물류 설비를 하나의 플랫폼으로 통합할 수 있는
                        제공합니다. 
                        </AboutText>

                        <AboutText>
                        저희의 Solution은 직관적인 UI를 통해 트래픽 제어를 가능하게 하고
                        물류로봇과 설비의 상호 운용성을 강화하여 유연하고 자동화된 작업을 가능하게 합니다.
                        </AboutText>
                        
                        <SkillsContainer>
                            <Skill>물류로봇(AMR/AGV)</Skill>
                            <Skill>물류 운영 시스템(ACS)</Skill>
                            <Skill>자동화 및 제어시스템(S/W)</Skill>
                        </SkillsContainer>
                    </AboutContent>
                </AboutContainer>
            </AboutSection>

            <Section>
                <SectionTitle>사업안내</SectionTitle>
                <ProjectGrid>
                    <ProjectCard>
                        <ProjectImage 
                            style={{ 
                                backgroundImage: `url(${agv})`}} 
                        />
                        <ProjectInfo>
                            <ProjectTitle>물류로봇(AMR/AGV)</ProjectTitle>
                            <ProjectDescription>
                            복잡하고 좁은 물류 공간에 특화된 물류 로봇입니다.
                            중앙 관제 시스템을 통해 광범위한 주행 정보를 획득하여
                            광범위한 경로 탐색 및 장애물 회피 기능을 제공합니다.
                            </ProjectDescription>
                            <SkillsContainer>
                                <Skill>물류로봇(AMR/AGV)</Skill>
                                <Skill>물류 운영 시스템(ACS)</Skill>
                                <Skill>자동화 및 제어시스템(S/W)</Skill>
                            </SkillsContainer>
                        </ProjectInfo>
                    </ProjectCard>

                    <ProjectCard>
                        <ProjectImage style={{ backgroundImage: `url(${acs})`  }} />
                        <ProjectInfo>
                            <ProjectTitle>물류 운영 시스템(ACS)</ProjectTitle>
                            <ProjectDescription>
                                1000대 이상 운영중에 있으며 물류 운영에 있어 생기는
                                다양한 문제들을 해결해 나아가고 있습니다.
                            </ProjectDescription>
                            <SkillsContainer>
                                <Skill>다수의 물류로봇 제어 및 운영</Skill>
                                <Skill>반송 및 배차 최적화</Skill>
                                <Skill>물류 통행량 제어 및 교차로 제어</Skill>
                                <Skill>자동 충전 및 유지보수 자동화</Skill>
                                <Skill>Non-Down 시스템</Skill>
                            </SkillsContainer>
                        </ProjectInfo>
                    </ProjectCard>
                    <ProjectCard>
                        <ProjectImage style={{ backgroundImage: `url(${smartFarm})` }} />
                        <ProjectInfo>
                            <ProjectTitle>자동화 및 제어시스템(S/W)</ProjectTitle>
                            <ProjectDescription>
                            산업 현장의 다양한 설비들을 효율적으로 제어하고
                            자동화하는 솔루션을 제공합니다.
                            </ProjectDescription>
                            <SkillsContainer>
                                <Skill>물류 설비 제어 S/W</Skill>
                                <Skill>진공 설비 제어 S/W </Skill>
                                <Skill>레이저 설비 제어 S/W</Skill>
                                <Skill>곤충 스마트팜 제어 S/W</Skill>
                            </SkillsContainer>
                        </ProjectInfo>
                    </ProjectCard>
                    
                </ProjectGrid>
            </Section>

            <Footer>
                <FooterContainer>
                    <FooterSection>
                        <h3>Contact</h3>
                        <ContactInfo>
                            <p>📧 eggplant@theeggplant.co.kr</p>
                            <SocialLinks>
                                <SocialIcon href="https://github.com" target="_blank">
                                    <i className="fab fa-github"></i>
                                </SocialIcon>
                                <SocialIcon href="https://linkedin.com" target="_blank">
                                    <i className="fab fa-linkedin"></i>
                                </SocialIcon>
                            </SocialLinks>
                        </ContactInfo>
                    </FooterSection>
                    
                    <FooterSection>
                        <h3>Location</h3>
                        <LocationInfo>
                            <p>경기도 화성시 동탄영천로150 </p>
                            <p>동탄실리콘앨리 A동 1415호</p>
                        </LocationInfo>
                    </FooterSection>
                    
                    <FooterSection>
                        <h3>Working Hours</h3>
                        <LocationInfo>
                            <p>월요일 - 금요일</p>
                            <p>09:00 AM - 06:00 PM</p>
                            <p>주말 및 공휴일 휴무</p>
                        </LocationInfo>
                    </FooterSection>
                </FooterContainer>
                
                <Copyright>
                © 2022 Eggplant. All rights reserved.
                </Copyright>
            </Footer>
        </MainContainer>
    );
};

export default DevPortfolio; 