import { Routes, Route, BrowserRouter } from 'react-router-dom';
import DevPortfolio from './container/DevPortfolio';

const App = () => (
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<DevPortfolio />} />
      </Routes>
  </BrowserRouter>


);

export default App; 